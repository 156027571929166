import { Typography } from "@material-ui/core";
import React from "react";

interface ErrorMessageProps {
    message: string | string[];
}

const messageTypography = (message: string, index?: number) => (
    <Typography key={index} color="error" variant="body1" children={message} />
);

export function ErrorMessage({ message }: ErrorMessageProps) {
    if (Array.isArray(message) && message.length > 0) {
        return (
            <React.Fragment>
                {message.map((msg, index) => messageTypography(msg, index))}
            </React.Fragment>
        );
    }
    if (typeof message === "string" && message !== "") {
        return messageTypography(message);
    }
    return null;
}
