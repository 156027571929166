import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    HoursOfOperationInput,
    Restaurant,
    RestaurantBranding,
    RestaurantContactInfo,
    RestaurantSettings,
    UserRestaurantsRole,
} from "../generated-interfaces/graphql";
import { MenuStages } from "../types/menuVersion";
import { RestaurantInfo } from "../types/restaurant";
import {
    getHighestAccessRestaurant,
    getRestaurantAccessLevels,
    RestaurantAccess,
} from "../utils/restaurants";
import {
    CallbackFunction,
    RestaurantUpdateDetails,
    StringOrNull,
} from "../utils/types";
import { USER_ACTIONS } from "./userReducer";

export interface RestaurantState {
    selectedRestaurant: Restaurant | null;
    selectedRestaurantCode: StringOrNull;
    restaurantsByUserRole: UserRestaurantsRole[];
    restaurantAccessLevels: RestaurantAccess;
    userRolesHaveLoaded: boolean;
    restaurantInfoFromHoneyAdmin: Record<string, RestaurantInfo>;
    primaryRestaurantCode: StringOrNull;
    selectedStage: string;
}

export const initialRestaurantState: RestaurantState = {
    selectedRestaurant: null,
    selectedRestaurantCode: null,
    restaurantsByUserRole: [],
    restaurantAccessLevels: {},
    userRolesHaveLoaded: false,
    restaurantInfoFromHoneyAdmin: {},
    primaryRestaurantCode: null,
    selectedStage: MenuStages.PLAYGROUND,
};

export const getRestaurantRolesAction = createAction("GET_RESTAURANT_ROLES");
export const getRestaurantInfoFromHoneyAdminAction = createAction<string>(
    "GET_RESTAURANT_INFO_FROM_HONEY_ADMIN"
);
export const updateRestaurantContactInfo = createAction<
    RestaurantContactInfo & CallbackFunction
>("UPDATE_RESTAURANT_INFO");
export const updateRestaurantSettings = createAction<
    RestaurantSettings & CallbackFunction
>("UPDATE_RESTAURANT_SETTINGS");
export const updateRestaurantBranding = createAction<
    RestaurantBranding & CallbackFunction
>("UPDATE_RESTAURANT_BRANDING");
export const updateHoursOfOperation = createAction<
    HoursOfOperationInput & CallbackFunction
>("UPDATE_HOURS_OF_OPERATION");
export const createHoursOfOperation = createAction<
    HoursOfOperationInput & CallbackFunction
>("CREATE_HOURS_OF_OPERATION");
export const updatePrimaryRestaurant = createAction<
    RestaurantUpdateDetails & CallbackFunction
>("UPDATE_PRIMARY_RESTAURANT");
export const updateStage = createAction<
    { stage: MenuStages } & CallbackFunction
>("UPDATE_STAGE");
export const restaurantState = createSlice({
    name: "restaurant",
    initialState: initialRestaurantState,
    reducers: {
        selectPrimaryRestaurant: (
            state,
            action: PayloadAction<{
                restaurantCode: string;
                primaryRestaurantCode?: string;
            }>
        ) => {
            state.selectedRestaurantCode = action.payload.restaurantCode;
            if (action.payload.primaryRestaurantCode) {
                if (state.selectedRestaurant) {
                    state.selectedRestaurant.primaryRestaurantCode =
                        action.payload.primaryRestaurantCode;
                }
                state.primaryRestaurantCode =
                    action.payload.primaryRestaurantCode;
            } else {
                state.primaryRestaurantCode = null;
                if (state.selectedRestaurant)
                    state.selectedRestaurant.primaryRestaurantCode = null;
            }
        },
        selectRestaurant: (state, action: PayloadAction<string>) => {
            state.selectedRestaurantCode = action.payload;
        },
        selectStage: (state, action: PayloadAction<string>) => {
            state.selectedStage = action.payload;
        },
        restaurantInfoLoadSuccess: (
            state,
            action: PayloadAction<Restaurant>
        ) => {
            state.selectedRestaurant = action.payload;
            state.selectedRestaurantCode = action.payload.restaurantCode;
            state.primaryRestaurantCode = action.payload.primaryRestaurantCode;
        },
        userRolesLoadSuccess: (
            state,
            action: PayloadAction<UserRestaurantsRole[]>
        ) => {
            state.restaurantsByUserRole = action.payload;
            state.restaurantAccessLevels = getRestaurantAccessLevels(
                action.payload
            );
            state.userRolesHaveLoaded = true;
            const preSelectedRestaurant = getHighestAccessRestaurant(
                action.payload
            );
            if (preSelectedRestaurant) {
                state.selectedRestaurantCode =
                    preSelectedRestaurant.restaurantCode;
                state.primaryRestaurantCode =
                    preSelectedRestaurant.primaryRestaurantCode;
            }
        },
        userRolesLoadFailure: (state) => {
            state.userRolesHaveLoaded = true;
        },
        restaurantInfoHoneyAdminLoadSuccess: (
            state,
            action: PayloadAction<RestaurantInfo>
        ) => {
            state.restaurantInfoFromHoneyAdmin[action.payload.restaurantCode] =
                action.payload;
        },
    },
    extraReducers: {
        [USER_ACTIONS.logout.toString()]: () => {
            return initialRestaurantState;
        },
        [USER_ACTIONS.loginSuccess.toString()]: (state) => {
            state.userRolesHaveLoaded = false;
        },
    },
});

export const RESTAURANT_ACTIONS = restaurantState.actions;
