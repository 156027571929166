import QueryString from "query-string";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { selectRestorationInProgress } from "../selectors/menu";
import {
    restaurantInfoSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { MenuStages } from "../types/menuVersion";

export default function useReadOnly() {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isMirrorRestro, setIsMirrorRestro] = useState(false);
    const selectedRestaurant = useSelector(restaurantInfoSelector);
    const restorationInProgress = useSelector(selectRestorationInProgress);
    const selectedStage = useSelector(selectedStageSelector);
    const { search } = useLocation();
    const queryParams = QueryString.parse(search);
    const menuVersion = queryParams["version"];

    useEffect(() => {
        const hasPrimaryRestro = !!selectedRestaurant?.primaryRestaurantCode;
        setIsMirrorRestro(hasPrimaryRestro);
        setIsReadOnly(
            selectedStage !== MenuStages.PLAYGROUND ||
                !!menuVersion ||
                restorationInProgress ||
                hasPrimaryRestro
        );
    }, [selectedRestaurant, selectedStage, menuVersion, restorationInProgress]);

    return { isReadOnly, isMirrorRestro, setIsReadOnly };
}
