import React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { MENU_ACTIONS, restoreDB } from "../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { TIME_OUT } from "../utils/constants";

export default function useRestoreDB() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const menuEditorRoutes = MENU_EDITOR_ROUTES(restaurantCode || "");
    return {
        restoreDB: ({ commitId }: { commitId: string }) => {
            dispatch(MENU_ACTIONS.setIsLoadingEntity(true));

            setTimeout(() => {
                dispatch(
                    restoreDB({
                        commitId,
                        successCallback: () => {
                            alert.success(
                                <div>Menu restoration in progress</div>,
                                {
                                    timeout: TIME_OUT,
                                }
                            );
                            history.push({
                                pathname: Object.values(menuEditorRoutes)[0]
                                    .path,
                            });
                        },
                        errorCallback: (error: any) => {
                            alert.error(
                                <div>
                                    <span>Error restoring menu version</span>
                                    <div>{error?.message}</div>
                                </div>,
                                {
                                    timeout: TIME_OUT,
                                }
                            );
                        },
                    })
                );
            }, TIME_OUT);
        },
    };
}
