import {
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    Theme,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "mui-rff";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, FormSpy } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Prompt, useLocation, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import DragContainer from "../../components/menu/DragContainer";
import { OverrideInput } from "../../components/menu/DragItemList";
import { NewInputProperties } from "../../components/menu/InputProperties";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { MenuType } from "../../constants/menu";
import {
    MenuItem as MenuItemType,
    MenuOverride,
    ModalityType,
    SecondaryType,
    SortOrder,
} from "../../generated-interfaces/graphql";
import {
    useCustomHistory,
    useDuplicatePayload,
    useVoiceProperties,
} from "../../hooks";
import {
    createModifierGroup,
    deleteModifierGroup,
    duplicateModifierGroup,
    getVoiceProperties,
    updateModifierGroup,
} from "../../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    ComponentType,
    IDuplicatePayload,
    ModifierGroupType,
} from "../../types/menu";
import { TIME_OUT } from "../../utils/constants";
import { isNumber } from "../../utils/helper-functions";
import {
    getValidKeyValuePair,
    menuItemsSelector,
    menuOverridesSelector,
    modifierGroupWithMenuItemsSelector,
} from "../../utils/menu";
import { DeleteItemType, GetIngestedDataType } from "../../utils/types";
import ActionItems from "./ActionItems";

const useStyles = makeStyles((theme: Theme) => ({
    selectLimitationContainer: {
        display: "flex",
        alignItems: "center",
    },
    selectLimitationInput: {
        width: "60px",
        "& .MuiFilledInput-input": {
            paddingTop: "12px",
        },
    },
}));

function NewModifierGroup(props: Props) {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const alert = useAlert();
    const modify = useLocation().pathname.endsWith("duplicate");

    const [blockNavigation, setBlockNavigation] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [customerRule1, setCustomerRule1] = useState(false);
    const [selectLimitValue, setSelectLimitValue] = useState(
        "selectionExactly"
    );
    const [menuItemList, setMenuItemList] = useState<MenuItemType[]>([]);
    const [originItemIdList, setOriginItemIdList] = useState("");
    const [overrides, setOverrides] = React.useState<{
        [menuItemId: string]: OverrideInput;
    }>({});

    const [defaultSelectedItemIds, setDefaultSelectedItemIds] = useState<
        number[]
    >([]);

    const modifyGroupMap = useSelector(modifierGroupWithMenuItemsSelector);
    const menuItemMap = useSelector(menuItemsSelector);
    const initialValues = modifyGroupMap[id];
    const { voiceProperties, setVoiceProperties } = useVoiceProperties(
        [{ key: "", value: "" }],
        initialValues?.name
    );
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const menuOverridesFromStore = useSelector(menuOverridesSelector);
    const { generateDuplicatePayload } = useDuplicatePayload();
    const { pushToHistory } = useCustomHistory();

    useEffect(() => {
        if (id !== "new" && initialValues) {
            let newItemSort = [...initialValues.sortOrder];
            newItemSort.sort((a, b) => {
                if (a.sortOrder === null || b.sortOrder === null) return 1;
                return a.sortOrder - b.sortOrder;
            });
            const childMenuItems = newItemSort.map(
                (order) => menuItemMap[order.id]
            );
            setMenuItemList(childMenuItems);
            const initialOverrideValues: {
                [menuItemId: string]: OverrideInput;
            } = {};
            initialValues.menuItems.forEach((id) =>
                menuItemMap[id].menuOverrides.forEach((item) => {
                    const {
                        objectPrimaryKey,
                        overrideKey,
                        overrideValue,
                        modalityType,
                        secondaryType,
                        secondaryId,
                    } = menuOverridesFromStore[item];
                    if (
                        secondaryType === SecondaryType.ModifierGroup &&
                        secondaryId === String(id)
                    ) {
                        initialOverrideValues[
                            `${objectPrimaryKey}_${modalityType}`
                        ] = {
                            objectPrimaryKey,
                            overrideKey,
                            overrideValue,
                            modalityType: modalityType as ModalityType,
                        };
                    }
                })
            );
            setOverrides(initialOverrideValues);
            setOriginItemIdList(childMenuItems.map((cm) => cm.id).join(""));
            if (initialValues.defaultSelectedItemIds) {
                setDefaultSelectedItemIds(
                    initialValues.defaultSelectedItemIds as number[]
                );
            }
            if (
                initialValues.minimumSelections === 0 &&
                initialValues.maximumSelections === -1
            ) {
                setCustomerRule1(false);
                (initialValues as any).selectionLimitCount = +1;
            } else if (
                initialValues.minimumSelections > 0 ||
                initialValues.maximumSelections > 0
            ) {
                setCustomerRule1(true);
                if (
                    initialValues.minimumSelections ===
                    initialValues.maximumSelections
                ) {
                    setSelectLimitValue("selectionExactly");
                    (initialValues as any).selectionLimitCount = +initialValues.minimumSelections;
                } else if (initialValues.minimumSelections === 0) {
                    setSelectLimitValue("selectionLess");
                    (initialValues as any).selectionLimitCount = +initialValues.maximumSelections;
                } else {
                    setSelectLimitValue("selectionMore");
                    (initialValues as any).selectionLimitCount = +initialValues.minimumSelections;
                }
            }
        } else {
            resetStateToDefault();
        }
    }, [initialValues]);

    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    async function validate(values: any) {
        if (!values.name) {
            return { required: "Display name is required" };
        }
        if (
            customerRule1 &&
            values.selectionLimitCount &&
            !isNumber(values.selectionLimitCount)
        ) {
            return { required: "Selection Limit Count Must Be An Integer" };
        }
        if (!menuItemList || menuItemList.length === 0) {
            return { required: "Modifier group should have at least 1 item." };
        }
        return;
    }

    const resetStateToDefault = () => {
        setBlockNavigation(false);
        setShowConfirmModal(false);
        setCustomerRule1(false);
        setMenuItemList([]);
        setOriginItemIdList("");
        setShowBackConfirmModal(false);
        setOverrides({});
        setVoiceProperties([{ key: "", value: "" }]);
        setSelectLimitValue("selectionExactly");
        setDefaultSelectedItemIds([]);
    };

    const onSubmit = (data: any) => {
        const menuItems: number[] = [];
        const sortOrder: SortOrder[] = [];

        menuItemList.forEach((menuItem: MenuItemType, index: number) => {
            menuItems.push(parseFloat(menuItem.id));
            sortOrder.push({
                id: parseFloat(menuItem.id),
                sortOrder: index,
            });
        });

        let maximumSelections = -1;
        let minimumSelections = 0;
        if (customerRule1) {
            if (data.selectionLimitCount === undefined) {
                data.selectionLimitCount = 1;
            }
            switch (selectLimitValue) {
                case "selectionLess":
                    minimumSelections = 0;
                    maximumSelections = parseFloat(data.selectionLimitCount);
                    break;
                case "selectionMore":
                    minimumSelections = parseFloat(data.selectionLimitCount);
                    maximumSelections = -1;
                    break;
                case "selectionExactly":
                    maximumSelections = parseFloat(data.selectionLimitCount);
                    minimumSelections = parseFloat(data.selectionLimitCount);
                    break;
            }
        }

        const modifierData = {
            id,
            name: data.name?.trim(),
            description: data.description?.trim(),
            maximumSelections,
            minimumSelections,
            defaultSelectedItemIds,
            menuItems,
            sortOrder,
            menuOverrides: Object.values(overrides).map((overrideInput) => {
                return {
                    secondaryType: SecondaryType.ModifierGroup,
                    secondaryId: id,
                    objectPrimaryKey: overrideInput.objectPrimaryKey,
                    overrideKey: overrideInput.overrideKey,
                    overrideValue: overrideInput.overrideValue,
                    modalityType: overrideInput.modalityType,
                } as MenuOverride;
            }),
            successCallback: () => {
                alert.success("Modifier Group Saved", {
                    timeout: TIME_OUT,
                });
            },
            errorCallback: () => {
                alert.error("Error Saving Modifier Group", {
                    timeout: TIME_OUT,
                });
            },
        } as any;

        const updatedVoiceProps = getValidKeyValuePair(voiceProperties);

        modifierData["voiceProperties"] =
            updatedVoiceProps?.length > 0 ? updatedVoiceProps : [];

        if (!data.id) {
            props.createModifierGroup(modifierData);
        } else {
            props.updateModifierGroup(modifierData);
        }
        setBlockNavigation(false);
    };

    const handleDeleteModifierGroup = () => {
        setShowConfirmModal(true);
    };

    const handleGoBack = (formIsDirty: boolean) => () => {
        if (formIsDirty) {
            setShowBackConfirmModal(true);
        } else {
            pushToHistory(`/${restaurantCode}/menu-editor/modifier-groups`);
        }
    };

    const handleOverrideChange = (
        objPrimaryKey: string,
        modalityType: ModalityType,
        overrideInput: OverrideInput
    ) => {
        setOverrides((prevOverrides) => ({
            ...prevOverrides,
            [`${objPrimaryKey}_${modalityType}`]: overrideInput,
        }));
    };

    const checkMenuItemListUpdated = () => {
        const newIdList = menuItemList.map((mg) => mg.id).join("");
        return !(newIdList === originItemIdList);
    };

    return (
        <React.Fragment>
            <Prompt
                when={blockNavigation}
                message={(location, action) => {
                    if (action === "POP") {
                        return "The changes you've made will be lost. Are you sure you want to discard the changes?";
                    }

                    return true;
                }}
            />
            {showConfirmModal && (
                <ConfirmDialog
                    content="Are you sure to delete this modifier?"
                    open={showConfirmModal}
                    onSuccess={() => {
                        props.deleteModifierGroup({
                            id: parseFloat(id),
                            name: initialValues?.name || "",
                            successCallback: () => {
                                alert.success("Modifier Group Deleted", {
                                    timeout: TIME_OUT,
                                });
                                pushToHistory(
                                    `/${restaurantCode}/menu-editor/modifier-groups`
                                );
                            },
                            errorCallback: () => {
                                alert.error("Error Deleting Modifier Group", {
                                    timeout: TIME_OUT,
                                });
                            },
                        });
                    }}
                    onCancel={() => setShowConfirmModal(false)}
                />
            )}
            {showBackConfirmModal && (
                <ConfirmDialog
                    title="Leave without saving?"
                    content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                    open={showBackConfirmModal}
                    onSuccess={() => {
                        pushToHistory(
                            `/${restaurantCode}/menu-editor/modifier-groups`
                        );
                    }}
                    onCancel={() => setShowBackConfirmModal(false)}
                />
            )}
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values, valid, dirty }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <FormSpy
                            onChange={(props) => {
                                setBlockNavigation(
                                    props.dirty || checkMenuItemListUpdated()
                                );
                            }}
                        />
                        <ActionItems
                            handleRemove={handleDeleteModifierGroup}
                            handleGoBack={handleGoBack(
                                dirty || checkMenuItemListUpdated()
                            )}
                            handleDuplicate={() => {
                                props.duplicateModifierGroup(
                                    generateDuplicatePayload(
                                        initialValues?.id,
                                        ComponentType.modifier
                                    )
                                );
                            }}
                            disableSave={!valid}
                            id={id}
                            menuType={MenuType.modifier}
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            fullWidth
                            id="name"
                            label="Display Name"
                            name="name"
                            margin="normal"
                            required
                            disabled={id !== "new" && !modify}
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            id="description"
                            label="Description (optional)"
                            placeholder="Enter description here"
                            name="description"
                            fullWidth={true}
                            multiline={true}
                            rows={4}
                            margin="normal"
                            variant="filled"
                            style={{
                                marginBottom: 40,
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <NewInputProperties
                            inputProps={voiceProperties}
                            setInputProps={setVoiceProperties}
                            heading="Set Voice Properties"
                        />
                        <DragContainer
                            listItemType={"menuItemForModifierGroup"}
                            secondaryId={initialValues?.id}
                            label="Add item to modifier group"
                            itemList={menuItemList}
                            setItemList={setMenuItemList}
                            setOverride={handleOverrideChange}
                            defaultSelectedItemIds={defaultSelectedItemIds}
                            setDefaultSelectedItemIds={
                                setDefaultSelectedItemIds
                            }
                        />
                        <Typography
                            variant="h6"
                            color="primary"
                            style={{ marginBottom: "20px" }}
                        >
                            Rules
                        </Typography>
                        <Typography>
                            Define how customers should select items in this
                            modifier group
                        </Typography>

                        <FormControlLabel
                            control={
                                <ReadOnlyWrapper
                                    element={Checkbox}
                                    checked={customerRule1}
                                    color="primary"
                                    name="customerRule1"
                                    onChange={(
                                        evt: ChangeEvent<HTMLInputElement>
                                    ) => setCustomerRule1(evt.target.checked)}
                                />
                            }
                            label="Customers must select an item"
                        />
                        {customerRule1 && (
                            <Grid
                                container
                                alignItems="center"
                                className={classes.selectLimitationContainer}
                            >
                                <Typography component="span">
                                    Maximum number of items customers can select
                                </Typography>
                                <Select
                                    id="selectLimitValue"
                                    name="selectLimitValue"
                                    value={selectLimitValue}
                                    onChange={(evt) =>
                                        setSelectLimitValue(
                                            evt.target.value as string
                                        )
                                    }
                                    style={{ margin: "0 20px" }}
                                >
                                    <ReadOnlyWrapper
                                        element={MenuItem}
                                        value="selectionExactly"
                                    >
                                        Exactly
                                    </ReadOnlyWrapper>
                                    <ReadOnlyWrapper
                                        element={MenuItem}
                                        value="selectionLess"
                                    >
                                        Less than or equal to
                                    </ReadOnlyWrapper>
                                    <ReadOnlyWrapper
                                        element={MenuItem}
                                        value="selectionMore"
                                    >
                                        More than or equal to
                                    </ReadOnlyWrapper>
                                </Select>
                                <ReadOnlyWrapper
                                    element={TextField}
                                    id="selectionLimitCount"
                                    name="selectionLimitCount"
                                    type="number"
                                    value={values?.selectionLimitCount || 1}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    className="custom-input-gray"
                                    fullWidth={false}
                                />
                            </Grid>
                        )}
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createModifierGroup: (data: ModifierGroupType) =>
            dispatch(createModifierGroup(data)),
        updateModifierGroup: (data: ModifierGroupType) =>
            dispatch(updateModifierGroup(data)),
        deleteModifierGroup: (data: DeleteItemType) =>
            dispatch(deleteModifierGroup(data)),
        duplicateModifierGroup: (data: IDuplicatePayload) =>
            dispatch(duplicateModifierGroup(data)),
        getVoiceProperties: (data: GetIngestedDataType) =>
            dispatch(getVoiceProperties(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(null, mapDispatchToProps);

export default connected(NewModifierGroup);
