import { colors } from "@material-ui/core";
import moment from "moment-timezone";
import { RolePermissions } from "../generated-interfaces/graphql";
import { MenuStages, menuStagesMapping } from "../types/menuVersion";

export const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const DAYS_SHORT = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const AVAILABILITY_STATE = {
    available: "Available all day",
    unavailable: "Unavailable all day",
    exact: "Exact Hours",
};

const timeZones = moment.tz.names();
const OFFST_TIMEZONE = timeZones.map((timeZone) => ({
    value: timeZone,
    label: " (GMT" + moment.tz(timeZone).format("Z") + ")" + timeZone,
}));

export { OFFST_TIMEZONE };

export const initialAvailabilities = [0, 1, 2, 3, 4, 5, 6].map((day) => ({
    day,
    hours: [],
    alwaysEnabled: true,
}));

export const ROLES_DISPLAY_VALUES = {
    [RolePermissions.PrestoAdmin.toString()]: "Presto Admin",
    [RolePermissions.PrestoCustomerSupport.toString()]: "Presto Customer Support",
    [RolePermissions.RestaurantManager.toString()]: "Restaurant Manager",
    [RolePermissions.RestaurantStaff.toString()]: "Restaurant Staff",
    [RolePermissions.Limited.toString()]: "Restaurant Limited",
};

export const DAYS_MAPPING = DAYS.map((day, index) => ({
    value: index,
    label: day,
}));

export const INTEGER_SIGNS = ["+", "-"];

export enum Environments {
    production = "PRODUCTION",
    bjs = "BJS",
    development = "DEVELOPMENT",
    sandbox = "SANDBOX",
    staging = "STAGING",
}

export enum DATE_FORMATS {
    dateAndTime = "D MMM YYYY h:mm a",
}

export const TIME_OUT = 5000;

export const StagesRank = {
    [MenuStages.PLAYGROUND.toString()]: 0,
    [MenuStages.PRELIVE.toString()]: 1,
    [MenuStages.LIVE.toString()]: 2,
};

const colorContrast = "500";

export const BannerColorMapping: Record<string, string> = {
    SUCCESS: colors.green[colorContrast],
    FAILED: colors.red[colorContrast],
    IN_PROGRESS: colors.purple[colorContrast],
    PENDING: colors.orange[colorContrast],
    LIVE: colors.green[colorContrast],
    PRELIVE: colors.purple[colorContrast],
    PLAYGROUND: colors.orange[colorContrast],
    ISACTIVE: colors.blue[colorContrast],
};

export const stagesList = [
    {
        label: menuStagesMapping[MenuStages.PLAYGROUND],
        value: MenuStages.PLAYGROUND,
    },
    {
        label: menuStagesMapping[MenuStages.LIVE],
        value: MenuStages.LIVE,
    },
];
