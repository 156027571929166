import {
    Checkbox,
    FormControlLabel,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrayMove from "array-move";
import React, { ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { componentTypeConfig } from "../../constants/menu";
import {
    MenuItem as MenuItemType,
    MenuItemSetting,
    MenuItemSettingKey,
    ModalityType,
    OverrideKey,
    OverrideType,
    SecondaryType,
} from "../../generated-interfaces/graphql";
import { useCustomHistory, useReadOnly } from "../../hooks";
import {
    currencySelector,
    selectedRestaurantCodeSelector,
} from "../../selectors/restaurant";
import { formatCurrency } from "../../utils/currency";
import {
    menuItemSettingsSelector,
    menuOverridesSelector,
    modifierGroupsSelector,
} from "../../utils/menu";
import { StringOrNull } from "../../utils/types";
import NumericInput from "../forms/NumericInput";
import ItemNavigation from "../ItemNavigation";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginLeft: "-36px",
        marginRight: "-40px",
        padding: "10px 0",
        backgroundColor: "rgba(0,0,0,0.08)",
        [theme.breakpoints.down("sm")]: {
            marginRight: "-8px",
            marginLeft: "-8px",
            paddingRight: "8px",
            paddingLeft: "8px",
        },
    },
    dragContainer: {
        backgroundColor: "white",
        marginLeft: "40px",
        width: "80%",
        borderRadius: "6px",
        boxShadow: "1px 3px 2px rgba(0,0,0,0.2)",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
        },
    },
    line: {
        display: "flex",
        alignItems: "center",
        padding: "5px 0",
        borderBottom: "1px solid #eeeeee",
    },
    priceWrapper: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
        "& > :nth-child(1)": {
            marginRight: "4px",
        },
    },
    price: {
        width: "60px",
        "&.override": {
            backgroundColor: theme.palette.primary.light,
            "& .MuiInputBase-input": {
                color: theme.palette.primary.dark,
            },
        },
    },
}));

export type DragItemType =
    | "menuItemForCategory"
    | "menuItemForModifierGroup"
    | "category";
export type OverrideInput = {
    objectPrimaryKey: string;
    overrideKey: OverrideKey;
    overrideValue: StringOrNull;
    modalityType: ModalityType;
};

interface SortableProps {
    value: MenuItemType;
    renderItem: (itemIds: number[]) => string;
    itemType: DragItemType;
    secondaryId?: string;
    removeId: string;
    setRemoveId: (id: string) => void;
    removeItem: (id: string) => void;
    setOverride: (override: OverrideInput) => void;
    settings: MenuItemSetting[];
    defaultSelectedItemIds?: number[];
    updateDefaultSelectedItemId: (id: number[]) => void;
}

const menuItemForCategoryTooltip =
    "Enter price override for this category here";
const menuItemForModifierGroupTooltip =
    "Enter price override for this modifier group here";

const DragHandle = SortableHandle(() => (
    <span style={{ margin: "0 10px" }}>
        <DehazeIcon />
    </span>
));
const SortableItem = SortableElement((data: SortableProps) => {
    const classes = useStyles();
    const overrides = useSelector(menuOverridesSelector);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isReadOnly } = useReadOnly();
    const [priceOverride, setPriceOverride] = React.useState<
        {
            [key in ModalityType]: null | string;
        }
    >({
        [ModalityType.Dinein]: null,
        [ModalityType.Delivery]: null,
        [ModalityType.Togo]: null,
    });
    const currency = useSelector(currencySelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { pushToHistory } = useCustomHistory();

    useEffect(() => {
        const priceOverridesFiltered = data.secondaryId
            ? data.value.menuOverrides
                  .map((mo) => overrides[mo])
                  .filter((override) => {
                      if (override) {
                          if (data.itemType === "menuItemForCategory") {
                              return (
                                  override.secondaryType ===
                                      SecondaryType.Category &&
                                  override.secondaryId === data.secondaryId &&
                                  override.overrideKey === OverrideKey.Price
                              );
                          } else if (
                              data.itemType === "menuItemForModifierGroup"
                          ) {
                              return (
                                  override.secondaryType ===
                                      SecondaryType.ModifierGroup &&
                                  override.secondaryId === data.secondaryId &&
                                  override.overrideKey === OverrideKey.Price
                              );
                          }
                      }
                      return false;
                  })
            : [];
        const dineInMenuOverride = priceOverridesFiltered.find(
            (mo) => mo.modalityType === ModalityType.Dinein
        );
        const toGoMenuOverride = priceOverridesFiltered.find(
            (mo) => mo.modalityType === ModalityType.Togo
        );
        const delveryMenuOverride = priceOverridesFiltered.find(
            (mo) => mo.modalityType === ModalityType.Delivery
        );
        setPriceOverride({
            [ModalityType.Dinein]: dineInMenuOverride
                ? dineInMenuOverride.overrideValue
                : null,
            [ModalityType.Togo]: toGoMenuOverride
                ? toGoMenuOverride.overrideValue
                : null,
            [ModalityType.Delivery]: delveryMenuOverride
                ? delveryMenuOverride.overrideValue
                : null,
        });
    }, [data.value]);

    const tooltip =
        data.itemType === "menuItemForModifierGroup"
            ? menuItemForModifierGroupTooltip
            : data.itemType === "menuItemForCategory"
            ? menuItemForCategoryTooltip
            : false;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onPriceUpdate = (price: string, modalityType: ModalityType) => {
        if (price[price.length - 1] !== ".") {
            const numberPrice = parseFloat(price);
            if (numberPrice === data.value.price) {
                setPriceOverride({
                    ...priceOverride,
                    [modalityType]: null,
                });
                data.setOverride({
                    objectPrimaryKey: data.value.id,
                    overrideKey: OverrideKey.Price,
                    overrideValue: null,
                    modalityType,
                });
            } else {
                setPriceOverride({
                    ...priceOverride,
                    [modalityType]: String(numberPrice),
                });
                data.setOverride({
                    objectPrimaryKey: data.value.id,
                    overrideKey: OverrideKey.Price,
                    overrideValue: String(numberPrice),
                    modalityType,
                });
            }
        } else {
            setPriceOverride({
                ...priceOverride,
                [modalityType]: price,
            });
        }
    };

    const resetPriceOverride = () => {
        data.setOverride({
            objectPrimaryKey: data.value.id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Dinein,
        });
        data.setOverride({
            objectPrimaryKey: data.value.id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Togo,
        });
        data.setOverride({
            objectPrimaryKey: data.value.id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Delivery,
        });
        setPriceOverride({
            [ModalityType.Dinein]: null,
            [ModalityType.Delivery]: null,
            [ModalityType.Togo]: null,
        });
        handleClose();
    };

    const menuItemPriceOverrides = data.value.menuOverrides
        .map((mo) => overrides[mo])
        .filter(
            (mo) =>
                !!mo &&
                !mo.secondaryId &&
                !mo.secondaryType &&
                mo.overrideType === OverrideType.MenuItem &&
                mo.overrideKey === OverrideKey.Price
        );
    const toGoPriceOverride = menuItemPriceOverrides.find(
        (mo) => mo.modalityType === ModalityType.Togo
    );
    const deliveryPriceOverride = menuItemPriceOverrides.find(
        (mo) => mo.modalityType === ModalityType.Delivery
    );

    const dineInDefaultPrice = data.value.price || 0;
    const toGoDefaultPrice = toGoPriceOverride
        ? toGoPriceOverride.overrideValue
        : dineInDefaultPrice;
    const deliveryDefaultPrice = deliveryPriceOverride
        ? deliveryPriceOverride.overrideValue
        : dineInDefaultPrice;

    const handleDefaultItem = (event: ChangeEvent<HTMLInputElement>) => {
        if (data.defaultSelectedItemIds) {
            const id = +event.target.value;
            if (event.target.checked) {
                if (
                    !data.defaultSelectedItemIds.some(
                        (selectedId) => selectedId === id
                    )
                ) {
                    const selectedItemIds: number[] = [
                        ...data.defaultSelectedItemIds,
                        id,
                    ];
                    data.updateDefaultSelectedItemId(selectedItemIds);
                }
            } else {
                const index = data.defaultSelectedItemIds.indexOf(id);
                if (index !== -1) {
                    var selectedItemIds = [...data.defaultSelectedItemIds];
                    selectedItemIds.splice(index, 1);
                    data.updateDefaultSelectedItemId(selectedItemIds);
                }
            }
        }
    };

    return (
        <div className={classes.line}>
            <DragHandle />
            <div style={{ flex: 0.3 }}>{data.value.name}</div>
            <div style={{ flex: 0.3 }}>
                {data.renderItem(data.value.modifierGroups)}
            </div>
            <div style={{ flex: 0.4, display: "flex" }}>
                <div className={classes.priceWrapper}>
                    <div>DL</div>
                    <Tooltip title={tooltip} aria-label={tooltip || ""}>
                        <ReadOnlyWrapper
                            element={NumericInput}
                            value={String(
                                priceOverride.DELIVERY || deliveryDefaultPrice
                            )}
                            className={`${classes.price}${
                                priceOverride.DELIVERY ? " override" : ""
                            }`}
                            handleUpdate={(price: string) =>
                                onPriceUpdate(price, ModalityType.Delivery)
                            }
                            prefixText={formatCurrency(currency)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            disabled={
                                !data.settings.find(
                                    (mis) =>
                                        mis &&
                                        mis.key ===
                                            MenuItemSettingKey.IsDeliveryEnabled &&
                                        mis.value === "true"
                                )
                            }
                        />
                    </Tooltip>
                </div>
                <div className={classes.priceWrapper}>
                    <div>TO</div>
                    <Tooltip title={tooltip} aria-label={tooltip || ""}>
                        <ReadOnlyWrapper
                            element={NumericInput}
                            className={`${classes.price}${
                                priceOverride.TOGO ? " override" : ""
                            }`}
                            value={String(
                                priceOverride.TOGO || toGoDefaultPrice
                            )}
                            handleUpdate={(price: string) =>
                                onPriceUpdate(price, ModalityType.Togo)
                            }
                            prefixText={formatCurrency(currency)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            disabled={
                                !data.settings.find(
                                    (mis) =>
                                        mis &&
                                        mis.key ===
                                            MenuItemSettingKey.IsToGoEnabled &&
                                        mis.value === "true"
                                )
                            }
                        />
                    </Tooltip>
                </div>
                <div className={classes.priceWrapper}>
                    <div>DI</div>
                    <Tooltip title={tooltip} aria-label={tooltip || ""}>
                        <ReadOnlyWrapper
                            element={NumericInput}
                            className={`${classes.price}${
                                priceOverride.DINEIN ? " override" : ""
                            }`}
                            value={String(
                                priceOverride.DINEIN || dineInDefaultPrice
                            )}
                            handleUpdate={(price: string) =>
                                onPriceUpdate(price, ModalityType.Dinein)
                            }
                            prefixText={formatCurrency(currency)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            disabled={
                                !data.settings.find(
                                    (mis) =>
                                        mis &&
                                        mis.key ===
                                            MenuItemSettingKey.IsDineInEnabled &&
                                        mis.value === "true"
                                )
                            }
                        />
                    </Tooltip>
                </div>
            </div>
            {data.defaultSelectedItemIds && (
                <FormControlLabel
                    label="Default"
                    control={
                        <ReadOnlyWrapper
                            element={Checkbox}
                            checked={data.defaultSelectedItemIds.some(
                                (id: number) => id + "" === data.value.id
                            )}
                            onChange={handleDefaultItem}
                            value={data.value.id}
                        />
                    }
                />
            )}
            {isReadOnly ? (
                <ItemNavigation
                    url={`/${restaurantCode}/menu-editor/items/${data.value.id}`}
                    title={componentTypeConfig["menuItem"].title}
                />
            ) : (
                <>
                    <IconButton
                        style={{ width: "40px", padding: 0 }}
                        onClick={(evt) => {
                            handleClick(evt);
                            data.setRemoveId(data.value.id);
                        }}
                    >
                        <MoreVertIcon style={{ width: "40px" }} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <ReadOnlyWrapper
                            element={MenuItem}
                            onClick={() => {
                                pushToHistory(
                                    `/${restaurantCode}/menu-editor/items/${data.removeId}`
                                );
                            }}
                        >
                            Edit
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper
                            element={MenuItem}
                            onClick={() => {
                                data.removeItem(data.removeId);
                                handleClose();
                            }}
                        >
                            Remove
                        </ReadOnlyWrapper>
                        {priceOverride.DELIVERY === null &&
                        priceOverride.DINEIN === null &&
                        priceOverride.TOGO === null ? null : (
                            <ReadOnlyWrapper
                                element={MenuItem}
                                onClick={resetPriceOverride}
                            >
                                Use Default Price
                            </ReadOnlyWrapper>
                        )}
                    </Menu>
                </>
            )}
        </div>
    );
});
const Container = SortableContainer((data: { children: React.ReactNode }) => {
    const classes = useStyles();
    return <div className={classes.dragContainer}>{data.children}</div>;
});

interface Props {
    listItemType: DragItemType;
    secondaryId?: string;
    itemList: MenuItemType[];
    setItemList: (itemList: MenuItemType[]) => void;
    removeItem: (id: string) => void;
    setOverride: (
        objPrimaryKey: string,
        modalityType: ModalityType,
        override: OverrideInput
    ) => void;
    defaultSelectedItemIds?: number[];
    updateDefaultSelectedItemId: (id: number[]) => void;
}

export default function DragItemList(props: Props) {
    const classes = useStyles();
    const [removeId, setRemoveId] = React.useState("");

    const { itemList, setItemList } = props;
    const modifyGroupMap = useSelector(modifierGroupsSelector);
    const menuSettings = useSelector(menuItemSettingsSelector);

    const onSortEnd = (data: { oldIndex: number; newIndex: number }) => {
        setItemList(ArrayMove(itemList, data.oldIndex, data.newIndex));
    };

    const renderItem = (modifierGroups: number[]): string => {
        const itemNames: string[] = [];
        modifierGroups.forEach((modify) => {
            itemNames.push(modifyGroupMap[modify]?.name);
        });
        if (itemNames.length <= 2) {
            return itemNames.join(", ");
        }
        return `${itemNames.slice(0, 2).join(", ")}, +${itemNames.length - 2}`;
    };

    return (
        <div className={classes.container}>
            <Container onSortEnd={onSortEnd} useDragHandle>
                {itemList.length > 0 &&
                    itemList.map(
                        (value, index) =>
                            value && (
                                <ReadOnlyWrapper
                                    element={SortableItem}
                                    key={`item-${value?.id}`}
                                    index={index}
                                    value={value}
                                    renderItem={renderItem}
                                    itemType={props.listItemType}
                                    secondaryId={props.secondaryId}
                                    setOverride={(override: OverrideInput) =>
                                        props.setOverride(
                                            value?.id,
                                            override.modalityType,
                                            override
                                        )
                                    }
                                    removeId={removeId}
                                    setRemoveId={setRemoveId}
                                    removeItem={props.removeItem}
                                    settings={value?.menuItemSettings.map(
                                        (setting) =>
                                            menuSettings[setting.toString()]
                                    )}
                                    defaultSelectedItemIds={
                                        props.defaultSelectedItemIds
                                    }
                                    updateDefaultSelectedItemId={(
                                        selectedItem: number[]
                                    ) =>
                                        props.updateDefaultSelectedItemId(
                                            selectedItem
                                        )
                                    }
                                />
                            )
                    )}
            </Container>
        </div>
    );
}
