import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    createMenuVersion,
    fetchActiveMenuCommitStage,
    fetchCommittedMenuVersion,
    fetchLatestVersionOnDB,
    fetchMenuCommitStage,
    fetchMenuVersionActivity,
    fetchMenuVersionRequestStatus,
    getMenuItems,
    MenuState,
    MENU_ACTIONS,
    promoteMenuVersion,
    resetMenuVersionData,
    restoreDB,
    restoreDBStatus,
} from "../reducers/menuReducer";
import { selectRestorationInProgress } from "../selectors/menu";
import {
    selectedRestaurantCodeSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { currentUserSelector } from "../selectors/user";
import { BasicAPIResponseType } from "../types/menu";
import {
    CreateMenuVersionResponseType,
    IFetchLatestVersionOnDBType,
    IFetchMenuVersionActionType,
    IFetchMenuVersionRequestStatusResponse,
    IFetchRestoreDBStatusType,
    IMenuHistory,
    IMenuHistoryResponse,
    IMenuVersionActivity,
    IMenuVersionActivityResponse,
    IMenuVersionRequestStatus,
    IPromoteMenuVersionActionType,
    IRestoreDBCallType,
    MenuStages,
} from "../types/menuVersion";
import { StagesRank } from "../utils/constants";
import { snakeToCamelCase, sortByDate } from "../utils/helper-functions";
import logger from "../utils/logger";
import { generateVoiceProperties } from "../utils/menu";
import { CreateMenuVersion, StringOrNull } from "../utils/types";
import {
    CreateMenuVersionCall,
    fetchActiveMenuCommitStageCall,
    fetchCommittedMenuVersionCall,
    fetchLatestVersionOnDBCall,
    fetchMenuCommitStageCall,
    fetchMenuVersionActivityCall,
    fetchMenuVersionPreSignedUrlCall,
    fetchMenuVersionRequestStatusCall,
    fetchRestoreDBStatusCall,
    promoteMenuVersionCall,
    restoreDBCall,
} from "../utils/webserviceAPI";

function* createMenuVersionSaga(action: PayloadAction<CreateMenuVersion>) {
    const { comment, successCallback, errorCallback } = action.payload;
    logger.debug("Attempting to create menu version");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const {
            username,
            firstName: first_name,
            lastName: last_name,
        } = yield select(currentUserSelector);

        const response: CreateMenuVersionResponseType = yield call(
            CreateMenuVersionCall,
            {
                comment,
                restaurantCode,
                user: {
                    username,
                    first_name,
                    last_name,
                },
                stage: stage.toLowerCase(),
            }
        );
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
        successCallback?.(response);
        logger.debug("Successfully triggered create menu version");
    } catch (error) {
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
        errorCallback?.(error);
        logger.error("Creating menu version Failed", error);
    }
}

function* fetchMenuCommitStageSaga() {
    logger.debug("Attempting to fetch stage specific committed menu");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        yield put(
            MENU_ACTIONS.updateStateProperty({
                versionList: [] as IMenuHistory[],
            } as MenuState)
        );
        const response: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchMenuCommitStageCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
        });
        const versionListMapping = (response?.data?.data || [])
            .map(
                ({
                    commit_id: commitId,
                    created_at: createdAt,
                    creator_first_name,
                    creator_last_name,
                    publisher_username: publisherUsername,
                    publisher_first_name,
                    publisher_last_name,
                    creator_username: creatorUsername,
                    id,
                    stage,
                    is_active: isActive,
                    updated_at: updatedAt,
                    comment,
                }) => ({
                    commitId,
                    createdAt,
                    creatorUsername,
                    creatorName: `${creator_first_name} ${creator_last_name}`,
                    publisherUsername,
                    publisherName: `${publisher_first_name} ${publisher_last_name}`,
                    id,
                    stage,
                    isActive,
                    updatedAt,
                    comment,
                })
            )
            .sort(
                ({ stage: itemStage1 }, { stage: itemStage2 }) =>
                    StagesRank[itemStage1] - StagesRank[itemStage2]
            )
            .reduce((list, item) => {
                list[item.commitId] = item;
                return list;
            }, {} as Record<string, IMenuHistory>);
        const versionList = Object.values(versionListMapping).sort(
            (a, b) => Number(b.commitId) - Number(a.commitId)
        );
        yield put(
            MENU_ACTIONS.loadMenuComitStage({
                versionList,
            })
        );
        logger.debug("Successfully got committed menu");
    } catch (error) {
        logger.error("Fetching committed menu Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* fetchActiveMenuCommitStageSaga() {
    logger.debug("Attempting to fetch stage specific active committed menu");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        yield put(
            MENU_ACTIONS.updateStateProperty({
                stagesOverview: [] as IMenuHistory[],
            } as MenuState)
        );
        const response: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchActiveMenuCommitStageCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
        });
        const stagesOverview = (response?.data?.data || [])
            .map(
                ({
                    commit_id: commitId,
                    created_at: createdAt,
                    creator_first_name,
                    creator_last_name,
                    publisher_username: publisherUsername,
                    publisher_first_name,
                    publisher_last_name,
                    creator_username: creatorUsername,
                    id,
                    stage,
                    is_active: isActive,
                    updated_at: updatedAt,
                    comment,
                }) => ({
                    commitId,
                    createdAt,
                    creatorUsername,
                    creatorName: `${creator_first_name} ${creator_last_name}`,
                    publisherUsername,
                    publisherName: `${publisher_first_name} ${publisher_last_name}`,
                    id,
                    stage,
                    isActive,
                    updatedAt,
                    comment,
                })
            )
            .filter(({ stage }) => stage.toUpperCase() !== MenuStages.PRELIVE)
            .sort(
                (a, b) =>
                    StagesRank[a.stage.toUpperCase()] -
                    StagesRank[b.stage.toUpperCase()]
            );
        yield put(MENU_ACTIONS.loadActiveMenuComitStage({ stagesOverview }));
        logger.debug("Successfully got active committed menu");
    } catch (error) {
        logger.error("Fetching active committed menu Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* fetchMenuVersionRequestStatusSaga() {
    logger.debug("Attempting to fetch menu version request status");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);

        yield put(
            MENU_ACTIONS.updateStateProperty({
                menuVersionRequestStatus: [] as IMenuVersionRequestStatus[],
            } as MenuState)
        );
        const response: {
            data: {
                data: IFetchMenuVersionRequestStatusResponse[];
                success: string;
            };
        } = yield call(fetchMenuVersionRequestStatusCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
        });
        const menuVersionRequestStatus: IMenuVersionRequestStatus[] = response?.data?.data
            .map(
                ({
                    id,
                    restaurant_code: restaurantCode,
                    request_id: requestId,
                    request_type: requestType,
                    status,
                    updated_at: updatedAt,
                    created_at: createdAt,
                    commit_id: commitId,
                    menu_commit_url: menuCommitUrl,
                }) => ({
                    id,
                    restaurantCode,
                    requestId,
                    requestType,
                    status,
                    updatedAt,
                    createdAt,
                    commitId,
                    menuCommitUrl,
                })
            )
            .sort((o1, o2) => Number(o2?.id) - Number(o1?.id));

        yield put(
            MENU_ACTIONS.loadMenuVersionRequestStatus({
                menuVersionRequestStatus,
            })
        );
        logger.debug("Successfully got menu version request status");
    } catch (error) {
        logger.error("Fetching menu version request status failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* promoteMenuVersionSaga(
    action: PayloadAction<IPromoteMenuVersionActionType>
) {
    const { successCallback, errorCallback, commitId, stage } = action.payload;
    logger.debug("Attempting to promote menu version");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const {
            username,
            firstName: first_name,
            lastName: last_name,
        } = yield select(currentUserSelector);
        const response: { data: BasicAPIResponseType } = yield call(
            promoteMenuVersionCall,
            {
                restaurantCode,
                stage,
                commitId,
                user: {
                    username,
                    first_name,
                    last_name,
                },
            }
        );
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
        if (response?.data?.status.toLowerCase() === "success") {
            yield resetMenuVersionDataSaga();
            successCallback?.();
        } else errorCallback?.();
        logger.debug("Successfully promoted menu version");
    } catch (error) {
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
        errorCallback?.({ message: error?.response?.data?.error_message });
        logger.error("Promoting menu version failed", error);
    }
}

function* menuItemsSaga(action: PayloadAction<IFetchMenuVersionActionType>) {
    logger.debug("Attempting to get menu items");
    const { commitId, errorCallback, successCallback } = action.payload;
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const output: {
            data: { data: { menu_url: string } };
        } = yield call(fetchMenuVersionPreSignedUrlCall, {
            restaurantCode,
            commitId,
            stage,
        });
        const {
            data: { menu_url: menuUrl },
        } = output?.data;
        const response: { data: any } = yield call(
            fetchCommittedMenuVersionCall,
            {
                menuUrl,
            }
        );
        const {
            menuItems,
            modifierGroups,
            categories,
            menuOverrides,
            menuItemSettings,
            timePeriods,
            posProperties,
            voiceProperties,
            commit_id,
        } = response?.data || {};
        yield put(
            MENU_ACTIONS.loadMenuSuccess({
                menuItems,
                modifierGroups,
                categories,
                timePeriods,
                menuOverrides,
                menuItemSettings,
                posProperties,
            })
        );

        const transformedVP = generateVoiceProperties(voiceProperties || []);
        yield put(
            MENU_ACTIONS.loadVoiceProperties({
                voiceProperties: transformedVP,
            })
        );
        // yield put(fetchLatestVersionOnDB({}));
        const latestVersionInfo = { commitId: commit_id } as IMenuHistory;

        yield put(
            MENU_ACTIONS.updateLatestVersionInfo({
                latestVersionInfo,
            })
        );
        successCallback?.();
        logger.debug("Successfully got menu items");
    } catch (error) {
        logger.error("Get Menu Items Failed", error);
        yield put(
            MENU_ACTIONS.loadMenuSuccess({
                menuItems: [],
                modifierGroups: [],
                categories: [],
                timePeriods: [],
                menuOverrides: [],
                menuItemSettings: [],
                posProperties: [],
            })
        );
        yield put(
            MENU_ACTIONS.loadVoiceProperties({
                voiceProperties: {},
            })
        );
        errorCallback?.(error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* fetchMenuVersionActivitySaga() {
    logger.debug("Attempting to fetch menu version request status");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        yield put(
            MENU_ACTIONS.updateStateProperty({
                menuVersionActivity: [] as IMenuVersionActivity[],
            } as MenuState)
        );
        const menuVersionActivityResponse: {
            data: {
                data: IMenuVersionActivityResponse[];
                success: string;
            };
        } = yield call(fetchMenuVersionActivityCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
        });
        const menuHistoryResponse: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchMenuCommitStageCall, {
            restaurantCode,
            stage: MenuStages.PLAYGROUND.toLowerCase(),
        });
        const versionList = (menuHistoryResponse?.data?.data || []).reduce(
            (acc, item) => {
                const { commit_id } = item;
                if (acc[commit_id]) return acc;
                acc[commit_id] = item;
                return acc;
            },
            {} as Record<string, IMenuHistoryResponse>
        );
        const menuVersionActivity: IMenuVersionActivity[] = menuVersionActivityResponse?.data?.data
            .map(
                ({
                    id,
                    restaurant_code: restaurantCode,
                    stage,
                    commit_id: commitId,
                    creator_first_name: publisherFirstName,
                    creator_last_name: publisherLastName,
                    creator_username: publisherUsername,
                    updated_at: updatedAt,
                    action_name: actionName,
                    is_active: isActive,
                }) => {
                    const {
                        creator_username: creatorUsername,
                        creator_first_name,
                        creator_last_name,
                        comment,
                        created_at: createdAt,
                    } = versionList[commitId];
                    return {
                        id,
                        restaurantCode,
                        stage,
                        creatorUsername,
                        creatorName: `${creator_first_name} ${creator_last_name}`,
                        publisherUsername,
                        publisherName: `${publisherFirstName} ${publisherLastName}`,
                        updatedAt,
                        createdAt,
                        commitId,
                        actionName,
                        isActive,
                        comment,
                    };
                }
            )
            .sort(sortByDate("updatedAt", "desc"));
        yield put(
            MENU_ACTIONS.loadMenuVersionActivity({
                menuVersionActivity,
            })
        );
        logger.debug("Successfully got menu version request status");
    } catch (error) {
        logger.error("Fetching menu version request status failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* restoreDBSaga(action: PayloadAction<IRestoreDBCallType>) {
    const { successCallback, errorCallback, commitId } = action.payload;
    logger.debug("Attempting to restore menu version");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );

        const stage: MenuStages = yield select(selectedStageSelector);
        const {
            username,
            firstName: first_name,
            lastName: last_name,
        } = yield select(currentUserSelector);
        const response: { data: BasicAPIResponseType } = yield call(
            restoreDBCall,
            {
                restaurantCode,
                stage: stage.toLowerCase(),
                commitId,
                user: {
                    username,
                    first_name,
                    last_name,
                },
            }
        );
        if (response?.data?.status.toLowerCase() === "success") {
            yield put(MENU_ACTIONS.updateRestorationInProgress(true));
            yield resetMenuVersionDataSaga();
            successCallback?.();
        } else errorCallback?.();
        logger.debug("Successfully initiated menu version restoration");
    } catch (error) {
        errorCallback?.({
            message: error?.response?.data?.error_message || error?.message,
        });
        logger.error("Restoring menu version failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* fetchRestoreDBStatusSaga() {
    logger.debug("Attempting to fetch restore DB status");
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const restorationInProgress: boolean = yield select(
            selectRestorationInProgress
        );
        const response: {
            data: IFetchRestoreDBStatusType;
            success: string;
        } = yield call(fetchRestoreDBStatusCall, {
            restaurantCode,
        });

        const { incomplete_jobs = [] } = response?.data;
        if (restorationInProgress && incomplete_jobs.length === 0) {
            yield put(getMenuItems());
        }
        yield put(
            MENU_ACTIONS.updateRestorationInProgress(incomplete_jobs.length > 0)
        );
        logger.debug("Successfully got restore db status");
    } catch (error) {
        logger.error("Fetching restore db status Failed", error);
    }
}

function* fetchLatestVersionOnDBSaga() {
    logger.debug("Attempting to fetch latest version on DB");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const response: {
            data: IFetchLatestVersionOnDBType;
            success: string;
        } = yield call(fetchLatestVersionOnDBCall, {
            restaurantCode,
            stage,
        });

        const { last_commit_on_db } = response?.data;
        const latestVersionInfo: IMenuHistory = Object.entries(
            last_commit_on_db || {}
        ).reduce((acc: any, [key, value]) => {
            const updatedKey = snakeToCamelCase(key);
            acc[updatedKey] = value;
            return acc as IMenuHistory;
        }, {} as IMenuHistory);
        yield put(MENU_ACTIONS.updateLatestVersionInfo({ latestVersionInfo }));
        logger.debug("Successfully got committed menu");
    } catch (error) {
        logger.error("Fetching committed menu Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* resetMenuVersionDataSaga(): Generator<any, any, any> {
    try {
        yield put(fetchMenuCommitStage());
        yield put(fetchActiveMenuCommitStage());
        yield put(fetchLatestVersionOnDB({}));
        yield put(fetchMenuVersionRequestStatus());
        yield put(fetchMenuVersionActivity());
    } catch (error) {
        logger.error("Error while resetting the menu version data", error);
    }
}

export default function* menuVersionSaga() {
    yield takeEvery(createMenuVersion.toString(), createMenuVersionSaga);
    yield takeEvery(fetchMenuCommitStage.toString(), fetchMenuCommitStageSaga);
    yield takeEvery(
        fetchActiveMenuCommitStage.toString(),
        fetchActiveMenuCommitStageSaga
    );
    yield takeEvery(
        fetchMenuVersionRequestStatus.toString(),
        fetchMenuVersionRequestStatusSaga
    );

    yield takeEvery(promoteMenuVersion.toString(), promoteMenuVersionSaga);
    yield takeEvery(fetchCommittedMenuVersion.toString(), menuItemsSaga);
    yield takeEvery(
        fetchMenuVersionActivity.toString(),
        fetchMenuVersionActivitySaga
    );
    yield takeEvery(restoreDB.toString(), restoreDBSaga);
    yield takeEvery(restoreDBStatus.toString(), fetchRestoreDBStatusSaga);
    yield takeEvery(
        fetchLatestVersionOnDB.toString(),
        fetchLatestVersionOnDBSaga
    );
    yield takeEvery(resetMenuVersionData.toString(), resetMenuVersionDataSaga);
}
