import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "mui-rff";
import { TextFieldProps } from "mui-rff/src/TextField";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_ACTIONS } from "../reducers/errorReducer";
import { isProfileLoadedSelector, qrCodeURLSelector } from "../selectors/user";
import { readEnvVariable } from "../utils/helper-functions";
import { LoginForm, LoginFormData } from "./forms/LoginForm";
import { Image } from "./layout/Image";
interface IMFALoginFormProps {
    onSubmit: (data: LoginFormData) => void;
    inputClassName?: string;
    inputLabelClassName?: string;
    formErrors: any;
    passwordInputRef: React.RefObject<HTMLInputElement>;
    mfLogin: ({ accessToken }: { accessToken: string }) => void;
    verifyToken: (tokenId: string) => void;
}

export function MFALogin(prop: IMFALoginFormProps) {
    const GOOGLE_AUTH_TOKEN = readEnvVariable("GOOGLE_CLIENT_ID");
    const dispatch = useDispatch();
    const qrCodeUrl = useSelector(qrCodeURLSelector);
    const isProfileLoaded = useSelector(isProfileLoadedSelector);
    const [show, setShow] = useState<boolean>(false);
    const {
        mfLogin,
        formErrors,
        inputClassName,
        inputLabelClassName,
        verifyToken,
    } = prop;

    useEffect(() => {
        (async () => {
            const gapi = await import("gapi-script").then((pack) => pack.gapi);
            gapi.load("client:auth2", () =>
                gapi?.client?.init({
                    clientId: GOOGLE_AUTH_TOKEN,
                    scope: "",
                })
            );
        })();
    }, [GOOGLE_AUTH_TOKEN]);

    const onSuccess = (res: any) => {
        if (res.tokenId) {
            verifyToken(res.tokenId);
        }
    };
    const onFailure = (err: any) => {
        dispatch(ERROR_ACTIONS.loginFailure([{ message: err.details }]));
    };

    async function validate({ accessToken }: { accessToken: string }) {
        if (!accessToken) {
            return { required: "Access code is required" };
        }
    }

    const baseInputProps: Partial<TextFieldProps> = {
        required: true,
        variant: "filled",
        InputLabelProps: { className: inputLabelClassName },
        FormHelperTextProps: { className: inputLabelClassName },
        placeholder: "Type here",
    };

    return (
        <div id="mfLoginForm">
            <Grid container alignItems="center" spacing={2} direction="column">
                {isProfileLoaded ? (
                    <>
                        {qrCodeUrl && (
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    direction="column"
                                >
                                    <Grid
                                        item
                                        direction="row"
                                        container
                                        alignItems="flex-end"
                                        justify="center"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            display="block"
                                            style={{ color: "white" }}
                                        >
                                            Scan with Google Authenticator App
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Image
                                            url={qrCodeUrl}
                                            isMenuItemImage={false}
                                            height={300}
                                            width={300}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <Form
                                onSubmit={mfLogin}
                                validate={validate}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <TextField
                                            autoFocus={!formErrors}
                                            autoComplete="off"
                                            label="Access code"
                                            name="accessToken"
                                            type={show ? "text" : "password"}
                                            helperText="Enter your access code"
                                            required
                                            InputProps={{
                                                className: inputClassName,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setShow(
                                                                    (s) => !s
                                                                );
                                                            }}
                                                            edge="end"
                                                        >
                                                            {show ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...baseInputProps}
                                        />
                                        <Button
                                            disableElevation
                                            style={{ float: "right" }}
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Log In
                                        </Button>
                                    </form>
                                )}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item>
                            <LoginForm {...prop}></LoginForm>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                direction="column"
                            >
                                <Grid
                                    item
                                    direction="row"
                                    alignItems="flex-end"
                                    justify="center"
                                    container
                                >
                                    <Typography
                                        variant="subtitle1"
                                        display="block"
                                        style={{ color: "white" }}
                                    >
                                        Login with Secure
                                        2-Factor-Authentication
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <GoogleLogin
                                        clientId={GOOGLE_AUTH_TOKEN}
                                        buttonText="Sign in with Google"
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        cookiePolicy="single_host_origin"
                                        autoLoad={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}
