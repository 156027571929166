import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";

const selectMenu = (state: RootState) => state.menu;

export const selectDidInitialMenuLoad = createSelector(
    selectMenu,
    (menu) => menu.didInitialLoad
);
export const selectMenuVersionRequestStatus = createSelector(
    selectMenu,
    (menu) => menu.menuVersionRequestStatus
);

export const selectRestorationInProgress = createSelector(
    selectMenu,
    (menu) => menu.restorationInProgress
);
export const selectStagesOverview = createSelector(
    selectMenu,
    (menu) => menu.stagesOverview
);
export const selectVersionList = createSelector(
    selectMenu,
    (menu) => menu.versionList
);
export const selectMenuVersionActivity = createSelector(
    selectMenu,
    (menu) => menu.menuVersionActivity
);
export const selectLatestVersionInfo = createSelector(
    selectMenu,
    (menu) => menu.latestVersionInfo
);
export const selectLatestVersionCommitIdInfo = createSelector(
    selectMenu,
    (menu) => menu?.latestVersionInfo?.commitId || ""
);
