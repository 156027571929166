import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import logger from "../utils/logger";

export const restaurantInfoSelector = (state: RootState) =>
    state.restaurant.selectedRestaurant;
export const restaurantAccessLevelsSelector = (state: RootState) =>
    state.restaurant.restaurantAccessLevels;
export const restaurantsByUserRoleSelector = (state: RootState) =>
    state.restaurant.restaurantsByUserRole;
export const selectedRestaurantCodeSelector = (state: RootState) => {
    if (!state.restaurant.selectedRestaurantCode) {
        logger.error("No Selected Restaurant/Restaurant Info Found", {
            restaurantState: state.restaurant,
        });
        // throw new Error('No Selected Restaurant/Restaurant Info Found');
        return null;
    }
    return state.restaurant.selectedRestaurantCode;
};

export const selectedRestaurantAccessSelector = createSelector(
    selectedRestaurantCodeSelector,
    restaurantAccessLevelsSelector,
    (selectedRestaurantCode, accessLevels) => {
        if (selectedRestaurantCode) {
            return accessLevels[selectedRestaurantCode]?.role || null;
        }
        return null;
    }
);

export const showOpenTabsOrderSectionSelector = (state: RootState) =>
    !!state.restaurant.selectedRestaurant?.restaurantCode &&
    state.restaurant.selectedRestaurant.restaurantCode in
        state.restaurant.restaurantInfoFromHoneyAdmin
        ? !state.restaurant.restaurantInfoFromHoneyAdmin[
              state.restaurant.selectedRestaurant.restaurantCode
          ].isPosEnabled
        : false;

export const currencySelector = (state: RootState) =>
    !!state.restaurant.selectedRestaurant?.restaurantCode &&
    state.restaurant.selectedRestaurant.restaurantCode in
        state.restaurant.restaurantInfoFromHoneyAdmin
        ? state.restaurant.restaurantInfoFromHoneyAdmin[
              state.restaurant.selectedRestaurant.restaurantCode
          ].currency
        : "USD";

export const selectedPrimaryRestaurantCodeSelector = (state: RootState) => {
    if (!state.restaurant.selectedRestaurantCode) {
        logger.error("No Selected Restaurant/Restaurant Info Found", {
            restaurantState: state.restaurant,
        });
        // throw new Error('No Selected Restaurant/Restaurant Info Found');
        return null;
    }
    if (state.restaurant.primaryRestaurantCode) {
        return state.restaurant.primaryRestaurantCode;
    }
    return state.restaurant.selectedRestaurantCode;
};

export const selectedStageSelector = (state: RootState) =>
    state.restaurant.selectedStage;
