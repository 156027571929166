import {
    AssignmentTurnedIn as AvailabilityIcon,
    Build as BuildIcon,
    History as HistoryIcon,
    RestaurantMenu as RestaurantMenuIcon,
    Settings as SettingsIcon
} from "@material-ui/icons";
import React, { ReactNode } from "react";
import Home from "./components/Home";
import { RolePermissions } from "./generated-interfaces/graphql";
import ForgotPasswordEmail from "./pages/Auth/ForgotPasswordEmail";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import ItemAvailability from "./pages/ItemAvailability";
import { MenuEditorWrapper } from "./pages/menu-editor/MenuEditorWrapper";
import MenuVersion from "./pages/menuVersion/MenuVersion";
import OperationHours from "./pages/RestaurantSetting/OperationHours";
import RestaurantSettings from "./pages/RestaurantSetting/RestaurantSetting";
import UserManagementWrapper from "./pages/user-management";
import { hasRoleAccess } from "./utils/restaurants";

const {
    RestaurantManager,
    RestaurantStaff,
    PrestoCustomerSupport,
    Limited,
} = RolePermissions;

export interface AppRoute {
    title: string;
    path: string;
    protected: boolean;
    accessLevelNeeded?: RolePermissions;
    component: ReactNode;
    disableAppWrapper?: boolean;
    visibleTab?: boolean;
    originalPath?: string;
    subRoutes?: {
        [name: string]: AppRoute;
    };
    rootTab?: boolean;
}

export const ROUTES: { [name: string]: AppRoute } = {
    login: {
        title: "Login",
        path: "/login",
        protected: false,
        component: <Login />,
        disableAppWrapper: true,
    },
    forgotPassword: {
        title: "Forgot Password",
        path: "/forgot-password",
        protected: false,
        component: <ForgotPasswordEmail />,
        disableAppWrapper: true,
    },
    resetPassword: {
        title: "Reset Password",
        path: "/reset-password",
        protected: false,
        component: <ResetPassword />,
        disableAppWrapper: true,
    },
    // dashboard: {
    //     title: 'Dashboard',
    //     path: '/dashboard',
    //     protected: true,
    //     component: <Dashboard />
    // },
    home: {
        //Logged-in user isn't mapped to any restaurant groups
        title: "Home",
        path: "/home",
        protected: true,
        accessLevelNeeded: undefined,
        component: <Home />,
    },
    operationHours: {
        title: "Restaurant Settings",
        path: "/:restaurantId/restaurant-settings/operation-hours",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <OperationHours />,
    },
    restaurantSettings: {
        title: "Restaurant Settings",
        path: "/:restaurantId/restaurant-settings",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <RestaurantSettings />,
    },
    menuEditor: {
        title: "Menu Editor",
        path: "/:restaurantId/menu-editor",
        protected: true,
        accessLevelNeeded: RestaurantStaff,
        component: <MenuEditorWrapper />,
    },
    menuVersion: {
        title: "Menu Version",
        path: "/:restaurantId/menu-version/:menuType?",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <MenuVersion />,
    },
    itemAvailability: {
        title: "Item Availability",
        path: "/:restaurantId/item-availability",
        protected: true,
        accessLevelNeeded: Limited,
        component: <ItemAvailability />,
    },
    userManagement: {
        title: "Account Management",
        path: "/:restaurantId/user-management",
        protected: true,
        accessLevelNeeded: PrestoCustomerSupport,
        component: <UserManagementWrapper />,
    },
};

export const SIDEBAR_LINKS = (restaurantId: string) => {
    const replaceRestId = (path: string) =>
        path?.replace(":restaurantId", restaurantId) || "";
    const replaceMenuType = (path: string) =>
        path?.replace("/:menuType", "") || "";
    const {
        menuEditor,
        menuVersion,
        itemAvailability,
        restaurantSettings,
        userManagement,
    } = ROUTES;

    return [
        {
            href: replaceRestId(menuEditor.path),
            icon: RestaurantMenuIcon,
            title: menuEditor.title,
            accessLevelNeeded: menuEditor.accessLevelNeeded,
        },
        {
            href: replaceMenuType(replaceRestId(menuVersion.path)),
            icon: HistoryIcon,
            title: menuVersion.title,
            accessLevelNeeded: menuVersion.accessLevelNeeded,
        },
        {
            href: replaceRestId(itemAvailability.path),
            icon: AvailabilityIcon,
            title: itemAvailability.title,
            accessLevelNeeded: itemAvailability.accessLevelNeeded,
        },
        {
            href: replaceRestId(restaurantSettings.path),
            icon: SettingsIcon,
            title: restaurantSettings.title,
            accessLevelNeeded: restaurantSettings.accessLevelNeeded,
        },
        {
            href: replaceRestId(userManagement.path),
            icon: BuildIcon,
            title: userManagement.title,
            accessLevelNeeded: userManagement.accessLevelNeeded,
        },
    ];
};

export const findRouteWithAccess = (
    selectedRestaurantAccess: RolePermissions
) => {
    const routesInOrderOfAppearance = [
        ROUTES.menuEditor,
        ROUTES.itemAvailability,
    ];
    for (let route of routesInOrderOfAppearance) {
        if (
            hasRoleAccess(
                route.accessLevelNeeded || null,
                selectedRestaurantAccess
            )
        ) {
            return route;
        }
    }
    return null;
};
