import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { errorState } from "./errorReducer";
import { eventsState } from "./eventsReducer";
import { menuState } from "./menuReducer";
import { restaurantState } from "./restaurantReducer";
import { userManagementState } from "./userManagementReducer";
import { userState } from "./userReducer";

const createRootReducer = (history: History) => {
    return combineReducers({
        router: connectRouter(history),
        user: userState.reducer,
        menu: menuState.reducer,
        restaurant: restaurantState.reducer,
        error: errorState.reducer,
        userManagement: userManagementState.reducer,
        eventsReducer: eventsState.reducer,
    });
};

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
