import { parse } from "query-string";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    fetchCommittedMenuVersion,
    getMenuItems,
} from "../reducers/menuReducer";
import { selectedStageSelector } from "../selectors/restaurant";
import { MenuStages } from "../types/menuVersion";
import { TIME_OUT } from "../utils/constants";
import { CallbackFunction } from "../utils/types";

export default function useLoadMenu() {
    const dispatch = useDispatch();
    const selectedStage = useSelector(selectedStageSelector);
    const location = useLocation();
    const queryParams = parse(location.search);
    const menuVersion = queryParams["version"];
    const alert = useAlert();
    return {
        loadMenu: useCallback(
            ({
                stage,
                successCallback,
            }: CallbackFunction & { stage?: MenuStages }) => {
                const latestStage = stage || selectedStage;
                if (latestStage !== MenuStages.PLAYGROUND || menuVersion) {
                    const payload = menuVersion
                        ? {
                              commitId: menuVersion as string,
                              errorCallback: () => {
                                  alert.error(
                                      `Error fetching menu for version ${menuVersion}`,
                                      {
                                          timeout: TIME_OUT,
                                      }
                                  );
                              },
                              successCallback,
                          }
                        : {
                              successCallback,
                          };
                    dispatch(fetchCommittedMenuVersion(payload));
                } else dispatch(getMenuItems({ successCallback }));
            },
            [alert, dispatch, menuVersion, selectedStage]
        ),
    };
}
