import {
    AppBar,
    Box,
    Button,
    IconButton,
    makeStyles,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import QueryString from "query-string";
import React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCommitDialog, useReadOnly } from "../../hooks";
import useRestoreDB from "../../hooks/useRestoreDB.hook";
import { reloadCache } from "../../reducers/menuReducer";
import {
    selectLatestVersionCommitIdInfo,
    selectRestorationInProgress,
} from "../../selectors/menu";
import { selectedStageSelector } from "../../selectors/restaurant";
import { MenuStages, menuStagesMapping } from "../../types/menuVersion";
import { TIME_OUT } from "../../utils/constants";
import CommitDialog from "../menuVersion/CommitDialog";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    refreshButton: {
        padding: `${theme.spacing(3 / 8)}px ${theme.spacing(9 / 8)}px`,
    },
    toolbar: {
        minHeight: theme.spacing(4),
        margin: `${theme.spacing(3 / 8)}px 0`,
    },
    saveButton: {
        minHeight: "0 !important",
        padding: `0 ${theme.spacing(5 / 8)}px`,
        borderWidth: 2,
    },
    title: {
        flexGrow: 1,
        fontSize: "0.75rem",
    },
}));

export default function Banner() {
    const classes = useStyles();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { isReadOnly } = useReadOnly();
    const { search } = useLocation();
    const queryParams = QueryString.parse(search);
    const menuVersion = queryParams["version"];
    const currentMenuVersion = useSelector(selectLatestVersionCommitIdInfo);
    const [
        disableRefreshCache,
        setDisableRefreshCache,
    ] = React.useState<boolean>(false);
    const selectedStage = useSelector(selectedStageSelector);
    const {
        open,
        isDialogDisabled,
        setIsDialogDisabled,
        toggle,
    } = useCommitDialog();
    const { restoreDB } = useRestoreDB();
    const restorationInProgress = useSelector(selectRestorationInProgress);
    const viewEditableText =
        selectedStage === MenuStages.PLAYGROUND &&
        !restorationInProgress &&
        !menuVersion
            ? "Editable"
            : "View";

    const versionText = menuVersion || currentMenuVersion || "";
    const restorationtext = restorationInProgress
        ? " | Restoration in progress..."
        : "";
    const bannerTitle = `Version ${versionText} (${viewEditableText}) | ${menuStagesMapping[
        selectedStage
    ].toUpperCase()} Stage${restorationtext}`;
    const displayEditMenu =
        !!isReadOnly &&
        !restorationInProgress &&
        !!menuVersion &&
        selectedStage !== MenuStages.LIVE;

    const handleRefreshCash = (event: any) => {
        setDisableRefreshCache(true);
        dispatch(
            reloadCache({
                successCallback: () => {
                    alert.success("Reload success", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: (err: string) => {
                    alert.error("Reload Error", {
                        timeout: TIME_OUT,
                    });
                },
            })
        );
        setDisableRefreshCache(false);
    };
    return (
        <Box style={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                color={isReadOnly ? "default" : "secondary"}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        variant="body1"
                        component="div"
                        data-testid="banner-title"
                        className={classes.title}
                        style={{ flexGrow: 1 }}
                    >
                        {bannerTitle}
                    </Typography>
                    <>
                        <CommitDialog
                            toggle={toggle}
                            open={open}
                            setIsDialogDisabled={setIsDialogDisabled}
                            onCommitSuccess={() => {
                                displayEditMenu &&
                                    restoreDB({
                                        commitId: String(menuVersion),
                                    });
                            }}
                        />
                        {selectedStage !== MenuStages.LIVE ? (
                            displayEditMenu ? (
                                <Button
                                    className={classes.saveButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={toggle}
                                >
                                    Edit this Menu
                                </Button>
                            ) : (
                                <>
                                    <ReadOnlyWrapper
                                        element={Button}
                                        className={classes.saveButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={toggle}
                                        disabled={isDialogDisabled}
                                    >
                                        Save as new version
                                    </ReadOnlyWrapper>
                                    <IconButton
                                        className={classes.refreshButton}
                                        color="primary"
                                        aria-label="refresh cache"
                                        component="label"
                                        onClick={handleRefreshCash}
                                        data-testid="refresh-cache-btn"
                                        disabled={disableRefreshCache}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </>
                            )
                        ) : null}
                    </>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
