import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialTableProps } from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";
import { Image } from "../../components/layout/Image";
import { Tag } from "../../components/Tag/Tag";
import { MenuType } from "../../constants/menu";
import { duplicateMenuItem } from "../../reducers/menuReducer";
import { currencySelector } from "../../selectors/restaurant";
import { ComponentType } from "../../types/menu";
import { formatCurrencyAndAmount } from "../../utils/currency";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../utils/data-tables";
import {
    menuItemsWithCategoriesSelector,
    MenuItemWithCategories,
    renderTableCollectionText,
} from "../../utils/menu";
import AddNewItem from "./AddNewItem";
import RowActions from "./RowActions";
import { getItemAvailabilityText } from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
    availability: {
        color: "white",
        "&.available": {
            backgroundColor: "#02cb63",
        },
        "&.available_tomorrow": {
            backgroundColor: "#9400ff",
        },
        "&.sold_out": {
            backgroundColor: "#ff0041",
        },
    },
}));

export function Items() {
    const classes = useStyles();
    const menuItems = useSelector(menuItemsWithCategoriesSelector);
    const currency = useSelector(currencySelector);

    const menuItemsTableConfig: MaterialTableProps<MenuItemWithCategories> = {
        columns: [
            {
                title: "Photo",
                field: "imageUrl",
                render: (data) => {
                    return (
                        <Image
                            url={data.imageUrl || undefined}
                            isMenuItemImage={true}
                            height={50}
                            width={50}
                        />
                    );
                },
            },
            { title: "Item Name", field: "name" },

            { title: "Display Name", field: "displayName" },
            {
                title: "POS Id",
                field: "posId",
            },
            {
                title: "Status",
                customSort: (a, b) => {
                    const aText = getItemAvailabilityText(a);
                    const bText = getItemAvailabilityText(b);
                    if (aText < bText) {
                        return -1;
                    }
                    if (aText > bText) {
                        return 1;
                    }
                    return 0;
                },
                render: (data) => {
                    const status = getItemAvailabilityText(data);
                    return (
                        <Tag
                            className={`${
                                classes.availability
                            } ${status.toLowerCase().replace(" ", "_")}`}
                        >
                            {status}
                        </Tag>
                    );
                },
            },
            {
                title: "Price",
                field: "price",
                render: (data) => formatCurrencyAndAmount(currency, data.price),
            },
            {
                title: "Categories",
                field: "categories",
                render: (data) =>
                    renderTableCollectionText(data.parentCategories),
            },
            {
                title: "Modifier Groups",
                field: "modifierGroups",
                render: (data) =>
                    renderTableCollectionText(data.childModifierGroups),
            },
            {
                title: "Actions",
                render: (data) => {
                    return (
                        <RowActions
                            data={data}
                            duplicateAction={duplicateMenuItem}
                            componentType={ComponentType.menuItem}
                            type={MenuType.menuItem}
                        />
                    );
                },
                align: "right",
            },
        ],
        // Shallow copy objects b/c they are immutable
        data: Object.values(menuItems).map((rowData) => ({ ...rowData })),
    };

    return (
        <div id="menu-items">
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h4" color="primary">
                        Items
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <AddNewItem type={MenuType.menuItem} />
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...menuItemsTableConfig} />
        </div>
    );
}
