import { CallbackFunction, StringOrNull } from "../utils/types";
import { RequestStatus } from "./menu";

export type createMenuVersionType = {
    comment: string;
    restaurantCode: StringOrNull;
    user: Record<string, string>;
    stage: string;
} & CallbackFunction;

export type CreateMenuVersionResponseType = {
    data: { status: string; request_id: string; message: string };
};

export enum MenuStages {
    PLAYGROUND = "PLAYGROUND",
    PRELIVE = "PRELIVE",
    LIVE = "LIVE",
    ISACTIVE = "ISACTIVE",
}

export interface IMenuHistory {
    id: string;
    creatorUsername: string;
    creatorName: string;
    publisherUsername: string;
    publisherName: string;
    commitId: string;
    updatedAt: string;
    createdAt: string;
    comment?: string;
    menuCommitUrl?: string;
    stage?: string;
    isActive: boolean;
}

export interface IMenuHistoryResponse {
    id: string;
    restaurant_code: string;
    creator_username: string;
    creator_first_name: string;
    creator_last_name: string;
    commit_id: string;
    updated_at: string;
    created_at: string;
    stage: MenuStages;
    is_active: boolean;
    publisher_username: string;
    publisher_first_name: string;
    publisher_last_name: string;
    commit_created_at: string;
    comment: string;
}

export interface IMenuHistoryActiveResponse extends IMenuHistoryResponse {
    stage: MenuStages;
    is_active: boolean;
}

export interface IMenuHistoryCommitResponse extends IMenuHistoryResponse {
    menu_commit_url: string;
    comment: string;
}

export interface IPromoteMenuVersionActionType extends CallbackFunction {
    stage?: string;
    commitId: string;
}

export interface IFetchMenuVersionActionType extends CallbackFunction {
    commitId?: string;
}

export interface IMenuVersionActivityResponse extends IMenuHistoryResponse {
    stage: MenuStages;
    is_active: boolean;
    action_name: string;
}

export interface IPromoteMenuVersionCallType
    extends IPromoteMenuVersionActionType {
    restaurantCode: StringOrNull;
    user: {
        first_name: string;
        last_name: string;
        username: string;
    };
}

export interface IActivateMenuVersionCallType
    extends IPromoteMenuVersionCallType {
    commitId: string;
}

export interface IRestoreDBActionType extends IPromoteMenuVersionActionType {
    commitId: string;
}

export interface IMenuVersionRequestStatus {
    id: string;
    restaurantCode?: string;
    requestId: string;
    requestType: string;
    status: keyof typeof RequestStatus;
    updatedAt: string;
    createdAt: string;
    commitId: string;
    menuCommitUrl?: StringOrNull;
}

export interface IFetchMenuVersionRequestStatusResponse {
    id: string;
    restaurant_code: string;
    request_id: string;
    request_type: string;
    status: keyof typeof RequestStatus;
    updated_at: string;
    created_at: string;
    commit_id: string;
    menu_commit_url: StringOrNull;
}

export interface IMenuVersionActivity {
    id: string;
    restaurantCode?: string;
    updatedAt: string;
    createdAt: string;
    commitId: string;
    actionName: string;
    creatorUsername: string;
    creatorName: string;
    publisherUsername: string;
    publisherName: string;
    comment: string;
    stage?: string;
    isActive: boolean;
}

export interface IRestoreDBCallType extends IPromoteMenuVersionCallType {}

export interface IFetchRestoreDBStatusType {
    incomplete_jobs: IMenuVersionRequestStatus[];
}

export interface IFetchLatestVersionOnDBType {
    last_commit_on_db: IMenuHistoryResponse;
}

export const menuStagesMapping: Record<string, string> = {
    [MenuStages.PLAYGROUND]: "Draft",
    [MenuStages.LIVE]: "Prod",
    [MenuStages.PRELIVE]: "",
};

export const menuStagesStatusMapping: Record<string, string> = {
    [MenuStages.PLAYGROUND]: "Unverified",
    [MenuStages.PRELIVE]: "Verified",
    [MenuStages.LIVE]: "Verified",
    [MenuStages.ISACTIVE]: "Live In Prod",
};
